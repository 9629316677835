<template>
<v-row>
    <v-col cols="9">
      <v-select @change="cardChange();" dense hide-details="auto" class="card-existing-badge"
                :outlined="!model.newCard"
                :filled="model.newCard"
                :disabled="model.newCard"
                v-model="model.existingCards"
                :items="donorExistingCardList"
                :class="!model.newCard && model.existingCards ? 'selectable' : ''"
                :color="!model.newCard && model.existingCards ? 'white' : 'primary'"
                :background-color="!model.newCard && model.existingCards ? 'primary' : ''"
                item-text="value"
                item-value="existing_pay_id"
                :placeholder="'Existing Card/s' "
                return-object
      >
        <template v-slot:selection="{ item }">
          <span :class="!model.newCard && model.existingCards ? 'text-white' : 'primary--text'" >{{ item.value }}</span>
        </template>
        <template v-slot:append>
          <v-badge bordered color="secondary" :content="existingCardLength"></v-badge>
        </template>
      </v-select>
    </v-col>
    <v-col cols="3">
          <v-switch dense hide-details class="pt-0"
            v-model="model.newCard"
            label="Use New card"
            color="primary"
          ></v-switch>
      </v-col>
    <v-col :cols="defaultModel.payment_type === 'CC' ? 6 : 12">
        <v-text-field ref="receipt_name" outlined :placeholder="`${defaultModel.payment_type === 'CC' ? 'Card' : 'Account'} Name`" hide-details="auto"
            v-model="model.receipt_name" required :error-messages="$helpers.errorMsg('name', $v.model.receipt_name, `${defaultModel.payment_type === 'CC' ? 'Card' : 'Account'} Name`)" />
    </v-col>
    <template v-if="defaultModel.payment_type === 'CC' ">
        <v-col cols="6">
            <div id="card-number" class="stripe-element-style rounded" :class="{'stripe-element-focus':  card.focus,
            'error-message': card.error !== '' || (submit && card.empty) }"></div>
            <div v-if="card.error !== '' || (submit && card.empty)" class="pa-0 error--text">
                <span v-if="submit && card.empty">Card Number is required</span>
                <span v-else>{{ card.error }}</span>
            </div>
        </v-col>
        <v-col cols="6">
            <div id="card-expiry" class="stripe-element-style rounded" :class="{'stripe-element-focus':  expiry.focus,
                'error-message': expiry.error !== '' || (submit && expiry.empty) }"></div>
            <div v-if="expiry.error !== '' || (submit && expiry.empty)" class="pa-0 error--text">
                <span v-if="submit && expiry.empty">Expiry MM/YY is required</span>
                <span v-else>{{ expiry.error }}</span>
            </div>
        </v-col>
        <v-col cols="6">
            <div id="card-cvc" class="stripe-element-style rounded" :class="{'stripe-element-focus':  cvc.focus,
                'error-message': cvc.error !== '' || (submit && cvc.empty) }"></div>
            <div v-if="cvc.error !== '' || (submit && cvc.empty)" class="pa-0 error--text">
                <span v-if="submit && cvc.empty">CVC is required</span>
                <span v-else>{{ cvc.error }}</span>
            </div>
        </v-col>
    </template>
    <template v-if="defaultModel.payment_type === 'DD' ">
        <v-col cols="12">                    
            <div id="au-bank-account-element" class="stripe-element-style rounded" :class="{'stripe-element-focus':  becsAcc.focus,
            'error-message': becsAcc.error !== '' || (submit && becsAcc.empty) }"></div>
            <div v-if="becsAcc.error !== '' || (submit && becsAcc.empty)" class="pa-0 error--text">
                <span v-if="submit && becsAcc.empty">Account Number is required</span>
                <span v-else>{{ becsAcc.error }}</span>
            </div>
        </v-col>
    </template>
    <v-col cols="12">
        <div v-if="stripeErr !== ''" class="pa-0 error--text">
        {{ stripeErr }}
        </div>
    </v-col>
    <v-col cols="12">
        <v-card-actions class="pa-0">
            <v-row class="ma-0 pa-3" align="center" justify="end">
                <v-btn :disabled="loading" color="primary" class="text-capitalize mr-3" @click="openNotify()">Save</v-btn>
                <v-btn :disabled="loading" color="secondary" class="text-capitalize" @click="$emit('enableAct', false)">Cancel</v-btn>
            </v-row>
        </v-card-actions>
    </v-col>
    <notify-modal :loading="loading" :modalData="modalData" :open="openModal" @save="saveNotify" @close="openModal = false"/>
</v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    props: ['stripe', 'modelData'],
    emits: ['enableAct'],
    components: {
        NotifyModal: () => import("@/pages/admin/sf-opportunity/components/NotifyModal"),
    },
    data() {
        return {
            model: {
                receipt_name: '',
                payment_method_id: '',
                existingCards: null,
                cardValidate: false,
                newCard: true,
                paymentMethod: 'CC',
            },
            auBankAccount: undefined,
            cardNumber: undefined,
            cardExpiry: undefined,
            cardCvc: undefined,
            card: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            expiry: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            cvc: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            becsAcc: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            style: {
                style: {
                    base: {
                        fontFamily: 'Mont',
                        '::placeholder': {
                            color: '#00000061',
                        },
                        fontSize: '16px',
                        lineHeight: '20px'
                    },
                        invalid: {
                        color: '#FF0000',
                    },
                }
            },
            stripeErr: '',
            modalData: {
                name: 'save',
                items: [],
                tableNotFound: 'No Data Found!.',
            },
            openModal: false,
            loading: false,
            defaultModel: {},
            submit: false
        }
    },
    computed: {
      donorExistingCardList() {
        const existCardLists = this.$store.getters["donorExistingCardList"];
        if (this.defaultModel.payment_type === 'DD') {
          return existCardLists.filter(item => item.type === 'au_becs_debit') || [];
        }
        return existCardLists.filter(item => item.type === 'card') || [];
      },
      existingCardLength() {
        return `${this.donorExistingCardList ? this.donorExistingCardList.length : 0}`;
      },
    },
    validations: {
        model: {
            receipt_name: { required }
        }
    },
    watch: {
        'model.newCard'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.toggleStripeElements(); // Call the method to toggle the disabled state whenever newCard changes
                if (newVal) {
                    this.$store.commit("updateModel", { cardValidate: false });
                    this.model.existingCards = null;
                }
            }
        },
        modelData(newVal) {
            this.defaultModel = newVal;
            this.model.receipt_name = newVal.receipt_name;
        }
    },
    created() {
        this.defaultModel = this.modelData;
        this.model.receipt_name = this.defaultModel.receipt_name;
    }, 
    mounted() {
        setTimeout(() => {
            this.setUpStripe();
        });
    },
    methods: {
        ...mapActions(['donateUpdateCard', 'setStripeSetupIndent']),

        cardChange() {
            this.model.cardValidate = true;
            this.model = {...this.model, ...this.model.existingCards}
        },
        openNotify() {
            // Set submit flag based on the state of the "Use New Card" switch
            this.submit = this.model.newCard;

            this.$v.$touch();
            if (this.$v && this.$v.$invalid ||
                (this.defaultModel.payment_type === 'CC' && this.submit && (!this.card.complete || !this.expiry.complete || !this.cvc.complete)) ||
                (this.defaultModel.payment_type === 'DD' && this.submit && !this.becsAcc.complete) || this.stripeErr !== '') {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.openModal = true;
            this.$emit('enableAct', true);
        },
        setUpStripe() {
            if (window.Stripe === undefined) {
                alert('Stripe V3 library not loaded!');
            } else {
                const elements = this.stripe.elements({
                    fonts:[
                        {
                        family: 'Mont',
                        src: 'url(/fonts/Mont/Mont-Regular.woff) format(woff)'
                        }
                    ]
                });
                if (this.defaultModel.payment_type === 'DD') {
                    this.auBankAccount = elements.create('auBankAccount', {...this.style, ...{ hideIcon: true, name: 'Account Number'}});
                    this.auBankAccount.mount('#au-bank-account-element');
                } else {
                    this.cardCvc = elements.create('cardCvc', {...this.style, ...{ placeholder: 'CVC' }});
                    this.cardExpiry = elements.create('cardExpiry', {...this.style, ...{ placeholder: 'MM/YY' }});
                    this.cardNumber = elements.create('cardNumber',  {...this.style, ...{ placeholder: 'Card Number' }});
                    this.cardCvc.mount('#card-cvc');
                    this.cardExpiry.mount('#card-expiry');
                    this.cardNumber.mount('#card-number');
                }
                this.toggleStripeElements();
                this.listenForErrors();
            }
        },
        toggleStripeElements() {
            if (!this.model.newCard) {
                if (this.defaultModel.payment_type === 'DD') {
                    this.auBankAccount.update({ disabled: true });
                } else {
                    this.cardCvc.update({ disabled: true });
                    this.cardExpiry.update({ disabled: true });
                    this.cardNumber.update({ disabled: true });
                }
            } else {
                if (this.defaultModel.payment_type === 'DD') {
                    this.auBankAccount.update({ disabled: false });
                } else {
                    this.cardCvc.update({ disabled: false });
                    this.cardExpiry.update({ disabled: false });
                    this.cardNumber.update({ disabled: false });
                }
            }
        },
        listenForErrors() {
            if (this.defaultModel.payment_type === 'DD') {
                this.becsAccErrors();
            } else {
                this.cardNumEvent();
               this.cardExpiryEvent();
               this.cardCvcEvent();
            }
        },
        becsAccErrors() {
            const self = this;
            this.auBankAccount.addEventListener('change', function (event) {
                self.stripeErr = '';
                self.becsAcc.error = event.error ? event.error.message : '';
                self.becsAcc.complete = event.complete;
                self.becsAcc.empty = event.empty;
            });
            this.auBankAccount.addEventListener('focus', function (event) {
                self.becsAcc.focus = true;
            });
            this.auBankAccount.addEventListener('blur', function (event) {
                self.becsAcc.focus = false;
            });
        },
        cardNumEvent() {
            const self = this;
            this.cardNumber.addEventListener('change', function (event) {
                self.stripeErr = '';
                self.card.error = event.error ? event.error.message : '';
                self.card.complete = event.complete;
                self.card.empty = event.empty;
            });
            this.cardNumber.addEventListener('focus', function (event) {
                self.card.focus = true;
            });
            this.cardNumber.addEventListener('blur', function (event) {
                self.card.focus = false;
            });
        },
        cardExpiryEvent() {
            const self = this;
            this.cardExpiry.addEventListener('change', function (event) {
                self.stripeErr = '';
                self.expiry.error = event.error ? event.error.message : '';
                self.expiry.complete = event.complete;
                self.expiry.empty = event.empty;
            });
            this.cardExpiry.addEventListener('focus', function (event) {
                self.expiry.focus = true;
            });
            this.cardExpiry.addEventListener('blur', function (event) {
                self.expiry.focus = false;
            });
        },
        cardCvcEvent() {
            const self = this;
            this.cardCvc.addEventListener('change', function (event) {
                self.stripeErr = '';
                self.cvc.error = event.error ? event.error.message : '';
                self.cvc.complete = event.complete;
                self.cvc.empty = event.empty;
            });
            this.cardCvc.addEventListener('focus', function (event) {
                self.cvc.focus = true;
            });
            this.cardCvc.addEventListener('blur', function (event) {
                self.cvc.focus = false;
            });
        },
        saveNotify () {
            this.loading = true;

            // Check if an existing card is selected
            if (this.model.existingCards) {
                // If an existing card is selected, proceed without validating new card details
                this.donatePayment();
            } else {
                // If a new card is being used, perform validation and stripe setup
                const paymentData = {
                    type: this.defaultModel.payment_type === 'DD' ? 'au_becs_debit' : 'card',
                    billing_details: {
                        name: this.model.receipt_name,
                        email: this.defaultModel.email
                    },
                }, reqData = { payment_method_types : ['card'] };
                if ( this.defaultModel.payment_type === 'DD') {
                    paymentData['au_becs_debit'] = this.auBankAccount;
                    reqData['payment_method_types'] = ['au_becs_debit'];
                } else {
                    paymentData['card'] = this.cardNumber;
                }
                this.setStripeSetupIndent(reqData)
                    .then((result) => {
                        if(result) this.stripeConfirmPayment(paymentData, result.client_secret);
                    }).catch(err => this.loading = false);
            }
        },
        stripeConfirmPayment(paymentData, clientSecret) {
            const confirmMethod = this.defaultModel.payment_type === 'DD' ? 'confirmAuBecsDebitSetup' : 'confirmCardSetup';
            this.stripe[confirmMethod](clientSecret, {payment_method: paymentData})
                .then((result) => this.respStripeToken(result)).catch(err => this.loading = false);
        },
        respStripeToken(result) {
            if (result.error) {
                this.stripeErr = result.error.message;
                this.loading = this.openModal = false;
            } else {
                this.model.payment_method_id = result.setupIntent.payment_method;
                this.stripeErr = '';
                this.donatePayment();
            }
        },
        donatePayment() {
            const { existingCards, payment_method_id, ...payload } = this.model;
            // If an existing card is selected, use its payment_method_id
            if (existingCards && existingCards.existing_pay_id) {
                payload.payment_method_id = existingCards.existing_pay_id;
            }
            const reqData = existingCards && existingCards.existing_pay_id ? payload : this.model;
            this.donateUpdateCard({id: this.defaultModel.id, reqData}).then(resp => {
                this.openModal = false;
                this.loading = false;
                this.$emit('enableAct', false);
            }).catch(err =>  this.loading = false);
        },
    }
}
</script>

<style scoped lang="scss">
.stripe-element-style {
    border: thin solid #a1a1a1 !important;
    height: 55px;
    padding: 16px;
}

.stripe-element-style:hover, .stripe-element-focus {
  border-width: 2px !important;
  border-color: var(--v-primary-base) !important;
}

.error-message, .error-message:hover {
  border: 2px solid  var(--v-danger-base) !important;
}
</style>